import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { toggleBanner } from '@redux/actions/cookieBanner';

import { isCookieBannerV3Enabled } from '@services/featureFlags';
import { withData } from '@services/surveyDataProvider';

import CookieBannerV1 from './V1';
import ConsentInputV1 from './V1/ConsentInput';
import CookieBannerV3 from './V3';
import ConsentInputV3 from './V3/ConsentInput';

//----------------------------------------------------------------------
// CookieBanner
//----------------------------------------------------------------------

export const mapSurveyDataToProps = (surveyData) => ({
  // See: https://jira.medallia.com/browse/OA-29061
  // As of 24CR4, Survey Engine can send `cookieNotice` being `undefined` with `cookieConfiguration`
  // having a valid value. This will be reflected in the Redux store with `cookieBanner.deprecatedCookies`
  // resulting in a length of `1` invalidating the previous
  // `Object.keys(surveyData.cookieBanner).length > 0` condition.
  enabled: Object.keys(surveyData.cookieBanner).length > 1,
  backButtonText: surveyData.cookieBanner.backButtonText
});

export const mapStateToProps = (state, { enabled, backButtonText }) => ({
  isOpen: enabled && state.cookieBanner.isOpen,
  consentDate: enabled ? state.cookieBanner.consentDate : '',
  fadeIn: enabled && !state.cookieBanner.opener,
  backButtonText,
  isRTL: get(state, ['pageSettings', 'isRTL'])
});

const actions = {
  onClose: toggleBanner
};

function CookieBanner(props) {
  const Banner = isCookieBannerV3Enabled() ? CookieBannerV3 : CookieBannerV1;
  return <Banner {...props} />;
}

const composedConnect = compose(withData(mapSurveyDataToProps), connect(mapStateToProps, actions));

export default composedConnect(CookieBanner);

//----------------------------------------------------------------------
// CookieBannerConsentInput
//----------------------------------------------------------------------

export function CookieBannerConsentInput() {
  const Input = isCookieBannerV3Enabled() ? ConsentInputV3 : ConsentInputV1;
  return <Input />;
}
