import { isEmpty } from 'lodash';
import get from 'lodash/get';
import { SurveyData } from 'types/SurveyData';

import { enableSurveyAPI } from '@services/api';

import { isLocalServer } from '@utils/meta';

export const featureFlagsList = [
  'add-selected-option-class',
  'advanced-accessibility',
  'enable-survey-api',
  'remove-textfield-fieldset',
  'wcag-21-enabled',
  'enable-textarea-placeholder',
  'enable-each-option-with-label-class',
  'use-geolocation-timeout',
  'use-geolocation-fetching-spinner',
  'use-geolocation-fetching-spinner-fixed',
  '20cr3_MPC1670_enable_WCAG21_part_2',
  '21cr4_MPC3935_enable_WCAG21_part_3',
  '20cr3_MPC2756_enable_ios_dropdown_with_keyboard_open_fix',
  '20cr3.1_MPC3125_enable_dropdown_accessibility',
  '20cr3.1_MPC3179_enable_not_jquery_v7',
  '20cr4_MPC3127_enable_ios_dropdown_scroll_to_top_fix',
  '22cr1_MPC4261_enable_WCAG21_part_4',
  '22cr4_MPC5297_enable_auto_save_api',
  '23cr2_MPC5193_enable_living_lens_upload_validation',
  // 24CR4
  '23cr4_MPC7425_enable_searchable_dropdown',
  '23cr4_MPC7316_a11y_wfc_enhancement',
  '23cr4_OA26580_a11y_enable_row_option_error_state',
  // 24CR2
  '24cr2_OA27535_a11y_enable_less_verbose_grid_row_captions',
  // 24CR3
  '24cr3_OA26533_enable_grid_row_cell_alignment',
  '24cr3_OA27908_enable_scroll_fix_rtl_matrix',
  // 24CR4
  '24cr4_OA28280_a11y_enable_grid_fieldset_wrapper',
  '24cr4_OA28717_a11y_remove_pagehead_skipnav_link',
  '24cr4_OA28747_a11y_normalize_lang_attribute',
  '24cr4_MPC8208_a11y_enable_textarea_accessibility',
  '24cr4_MPC8131_enable_enhanced_cookie_banner',
  '24cr4_MPC8248_enable_faro_rum',
  '24cr4_MPC8530_surveys_runtime_debugger'
] as const;

export type ClientSideRenderingFeatureFlag = keyof typeof featureFlagsList;

const featureFlagsManifest: Record<string, boolean> = {};

export const clearFeatureFlags = () =>
  featureFlagsList.forEach((featureFlag) => {
    featureFlagsManifest[featureFlag] = false;
  });

clearFeatureFlags();

export const featureFlagGetter = (id: string) => () => featureFlagsManifest[id];

//----------------------------------------------------------------------
// Getters
//----------------------------------------------------------------------

export const isWCAG21_4_Enabled_FF = featureFlagGetter('22cr1_MPC4261_enable_WCAG21_part_4');

const advancedAccessibilityGetter = featureFlagGetter('advanced-accessibility');

const removeTextfieldGetter = featureFlagGetter('remove-textfield-fieldset');

const isGeolocationFetchingSpinner_FF_Enabled = featureFlagGetter(
  'use-geolocation-fetching-spinner'
);

export const isWCAG21_FF_Enabled = featureFlagGetter('wcag-21-enabled');

export const isWCAG21_2_Enabled_FF = featureFlagGetter('20cr3_MPC1670_enable_WCAG21_part_2');

export const isWCAG21_3_Enabled_FF = featureFlagGetter('21cr4_MPC3935_enable_WCAG21_part_3');

export const isWCAG21_2_Enabled = () => isWCAG21_3_Enabled_FF() || isWCAG21_2_Enabled_FF();

export const isWCAG21Enabled = () =>
  isWCAG21_4_Enabled_FF() ||
  isWCAG21_3_Enabled_FF() ||
  isWCAG21_2_Enabled_FF() ||
  isWCAG21_FF_Enabled();

export const isSurveyAPIEnabled = featureFlagGetter('enable-survey-api');

export const isAddingSelectedClassEnabled = featureFlagGetter('add-selected-option-class');

export const isTextfieldWithoutFieldsetEnabled = () => isWCAG21Enabled() || removeTextfieldGetter();

export const isAdvancedAccessibilityEnabled = () =>
  isWCAG21Enabled() || advancedAccessibilityGetter();

export const isTextareaPlaceholderEnabled = featureFlagGetter('enable-textarea-placeholder');

export const isLabelForEachOptionClassEnabled = featureFlagGetter(
  'enable-each-option-with-label-class'
);

export const isGeolocationTimeoutEnabled = featureFlagGetter('use-geolocation-timeout');

export const isGeolocationFetchingSpinnerFixed_FF_Enabled = featureFlagGetter(
  'use-geolocation-fetching-spinner-fixed'
);

export const isGeolocationFetchingSpinnerEnabled = () =>
  isGeolocationFetchingSpinner_FF_Enabled() || isGeolocationFetchingSpinnerFixed_FF_Enabled();

export const isIosDropdownOpeningWhenKeyboardShownFixEnabled = featureFlagGetter(
  '20cr3_MPC2756_enable_ios_dropdown_with_keyboard_open_fix'
);

export const isDropdownAccessibilityEnabled = featureFlagGetter(
  '20cr3.1_MPC3125_enable_dropdown_accessibility'
);

//----------------------------------------------------------------------
// Deprecated/Legacy
//----------------------------------------------------------------------

export const isjQueryDisabled = featureFlagGetter('20cr3.1_MPC3179_enable_not_jquery_v7');

export const isIosDropdownScrollToTopFixEnabled = featureFlagGetter(
  '20cr4_MPC3127_enable_ios_dropdown_scroll_to_top_fix'
);
export const isLivingLensUploadValidationEnabled = featureFlagGetter(
  '23cr2_MPC5193_enable_living_lens_upload_validation'
);

//----------------------------------------------------------------------
// 23CR4
//----------------------------------------------------------------------

export const isSearchableDropdownEnabled = featureFlagGetter(
  '23cr4_MPC7425_enable_searchable_dropdown'
);

export const isRowOptionErrorStateEnabled = featureFlagGetter(
  '23cr4_OA26580_a11y_enable_row_option_error_state'
);

export const isA11yWfcEnhancementEnabled = featureFlagGetter('23cr4_MPC7316_a11y_wfc_enhancement');

// Cookie Banner V3 has no specific FF, it's enabled via this configuration param.
// Refer to: https://jira.medallia.com/browse/MPC-6358
export const isCookieBannerV3Enabled = () =>
  get(window, ['surveyData', 'cookieNotice', 'cookieVersion'], '') === 'V3';

//----------------------------------------------------------------------
// 24CR2
//----------------------------------------------------------------------

export const isLessVerboseGridRowCaptionsEnabled = featureFlagGetter(
  '24cr2_OA27535_a11y_enable_less_verbose_grid_row_captions'
);

//----------------------------------------------------------------------
// 24CR3
//----------------------------------------------------------------------

export const isGridRowCellAlignmentEnabled = featureFlagGetter(
  '24cr3_OA26533_enable_grid_row_cell_alignment'
);

export const isScrollFixForRTLMatrixEnabled = featureFlagGetter(
  '24cr3_OA27908_enable_scroll_fix_rtl_matrix'
);

//----------------------------------------------------------------------
// 24CR4
//----------------------------------------------------------------------

export const isGridFieldsetWrapperEnabled = featureFlagGetter(
  '24cr4_OA28280_a11y_enable_grid_fieldset_wrapper'
);

export const isPageHeadSkipNavLinkRemoved = featureFlagGetter(
  '24cr4_OA28717_a11y_remove_pagehead_skipnav_link'
);

export const isNormalizedLangAttributeEnabled = featureFlagGetter(
  '24cr4_OA28747_a11y_normalize_lang_attribute'
);

export const isTextAreaAccessibilityEnabled = featureFlagGetter(
  '24cr4_MPC8208_a11y_enable_textarea_accessibility'
);

export const isEnhancedCookieBannerEnabled = featureFlagGetter(
  '24cr4_MPC8131_enable_enhanced_cookie_banner'
);

export const isFaroRUMEnabled = featureFlagGetter('24cr4_MPC8248_enable_faro_rum');

export const isSurveysRuntimeDebuggerEnabled = featureFlagGetter(
  '24cr4_MPC8530_surveys_runtime_debugger'
);

//----------------------------------------------------------------------
// utils
//----------------------------------------------------------------------

export const setFeatureFlags = (surveyData: SurveyData) => {
  // latest SE sends FF grouped
  if (surveyData.featureFlags) {
    Object.assign(featureFlagsManifest, surveyData.featureFlags);
  } else {
    // 'advanced-accessibility' is a particular case because it was added before having FF object
    featureFlagsManifest['advanced-accessibility'] =
      !!window.surveyData.isAdvancedAccessibilityEnabled;
  }

  if (isSurveyAPIEnabled()) {
    enableSurveyAPI();
  }
};

export function normalizeFeatureFlagsList(featureFlags: SurveyData['featureFlags']) {
  return featureFlagsList.reduce(
    (entries, id) => ({ ...entries, [id]: Boolean(featureFlags[id]) }),
    {}
  ) as Record<ClientSideRenderingFeatureFlag, boolean>;
}

//----------------------------------------------------------------------
// Back-End FFs
//----------------------------------------------------------------------

export const isCookieBannerEnabled = () => !isEmpty(window.surveyData.cookieNotice);

export function isjQueryEnabled() {
  const documentScripts = Array.from(document.getElementsByTagName('script')).map(
    (item) => item.src
  );

  if (documentScripts.some((url) => url.includes('jquery-1.7.1.min.js'))) {
    return false;
  }

  return true;
}

export function isSurveysNextEnabled() {
  if (isLocalServer()) {
    return true;
  }

  const documentScripts = Array.from(document.getElementsByTagName('script')).map(
    (item) => item.src
  );

  if (documentScripts.some((url) => url.includes('react-surveys-next'))) {
    return true;
  }

  return false;
}

export function normalizeSurveyEngineFeatureFlagsList() {
  return [
    {
      id: '20cr3.1_MPC3179_enable_remove_survey_engine_jquery',
      value: isjQueryEnabled(),
      description: 'This is inferred based on whether the jQuery bundle was loaded or not.'
    },
    {
      id: '21cr1_MPC3344_enable_cookie_notice',
      value: isCookieBannerEnabled(),
      description: 'This is inferred based on whether surveyData.cookieNotice is populated or not.'
    },
    {
      id: '23cr1_MPC5484_enable_performance_improvements',
      value: isSurveysNextEnabled(),
      description:
        'This is inferred based on whether the react-surveys-next bundle was loaded or not.'
    }
  ] as const;
}
